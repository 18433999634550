import Vue from "vue";
import VueRouter from "vue-router";
import md5 from "js-md5";
import config from "@/config";
import store from "@/store";

import pcroutes from "@/router/pc";
import webroutes from "@/router/web";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.replace;
VueRouter.prototype.replace = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const checkIsLogin = () => {
  let session = Vue.localStorage.get(md5("session"));
  // 合并
  if (location.href.split("?")[1] && !session) {
    session = location.href.split("?")[1];
    session = Vue.localStorage.set(md5("session"), session);
    window.location.href = location.href.split("?")[0];
  }
  if (session) {
    return true;
  } else {
    return false;
  }
};

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: config.pcPrefix + "/frent/index",
      component: () => import("@/layout/Content.vue"),
      children: [pcroutes, webroutes],
    },
    {
      path: "/KDRHB/frent/exhibition/:id",
      meta: { title: "查看大屏数据" },
      component: () => import("@/views/web/equipment/bigScreen/BigScreen.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const meta = to.meta;
  if (meta && meta.title) {
    document.title = meta.title;
    store.commit("updateTitle", meta.title);
  } else {
    store.commit("updateTitle", "");
  }
  const path = to.path.split("/");
  if (path[1] === "") {
    next();
  } else {
    let prefix = "";
    let module = "";
    let pageUrl = "";
    let url = to.path;
    if (path.length <= 4) {
      prefix = "/" + path[1];
      module = "/" + [path[1], path[2]].join("/");
      pageUrl = "/" + [path[1], path[2], path[3]].join("/");
      url = to.path;
    } else {
      prefix = "/" + path[1];
      module = "/" + [path[1], path[2]].join("/");
      pageUrl = "/" + [path[1], path[2], path[3]].join("/");
      url = "/" + [path[1], path[2], path[3], path[4]].join("/");
    }
    store.commit("updateUri", {
      prefix: prefix,
      module: module,
      page: pageUrl,
      url: url,
    });
    let loginRoute = "";
    if (prefix === config.pcPrefix) {
      loginRoute = config.pcPrefix + "/pc/login";
    } else {
      loginRoute = config.webPrefix + "/auth/login";
    }
    if (to.path === loginRoute) {
      next();
    } else {
      if (checkIsLogin()) {
        next();
      } else {
        next({ path: loginRoute, replace: true });
      }
    }
  }
});

export default router;
