// 文件服务器
const storageHost = "";
// 接口服务器
const apiHost = "";
// ws服务器
const wsHost = "wss://monitor.wxlpool.com/mqtt";
// const wsHost = "ws://192.168.10.162:8083/mqtt";
// 企业标识
const ENTER_CODE = "kdrhb";

// 路由标识
const WEBPREFIX = "/web";
const PCPREFIX = "/KDRHB";
export default {
  host: apiHost,
  storage: storageHost,
  wsHost: wsHost,
  enterCode: ENTER_CODE,
  webPrefix: WEBPREFIX,
  pcPrefix: PCPREFIX,
};
