<template>
  <div class="web-authorize-main-content">
    <el-card>
      <div class="content">
        <div class="top">
          <slot name="top">
            <el-page-header
              @back="goBack"
              v-if="showBack"
              class="header-back"
              :content="title"
            >
            </el-page-header>
          </slot>
        </div>
        <div class="borsolid" v-if="showBorder"></div>
        <div class="main">
          <slot></slot>
        </div>
        <div class="foot">
          <slot name="bottom"></slot>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "返回上级",
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  methods: {
    goBack() {
      this.$router.back();
    },
  },

  mounted() {},

  computed: {},

  components: {},
};
</script>

<style lang="less" scoped>
.web-authorize-main-content {
  padding: 20px 20px 0;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .main {
    flex: 1;
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .foot {
    display: flex;
    justify-content: center;
  }
}

.borsolid {
  height: 1px;
  background: #eaeaea;
  margin-bottom: 15px;
}

.header-back {
  display: flex;
  line-height: 30px;
  margin-bottom: 20px;
}

/deep/ .el-page-header__content {
  font-size: 16px;
}
</style>
