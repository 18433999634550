/**
 * PC菜单
 */
import config from "@/config";
export default {
  path: config.pcPrefix,
  meta: { title: "首页" },
  component: () => import("@/layout/Pc.vue"),
  children: [
    {
      path: "frent/index",
      meta: { title: "首页" },
      component: () => import("@/views/pc/web/home/HomeIndex.vue"),
    },
    {
      path: "pc/login",
      meta: { title: "登陆" },
      component: () => import("@/views/pc/authorize/login/LoginIndex.vue"),
    },
    {
      path: "pc/drug",
      meta: { title: "产品" },
      component: () =>
        import("@/views/pc/navigation/medicine/MedicineIndex.vue"),
    },
    {
      path: "pc/drug/detail/:id",
      meta: { title: "产品详情" },
      component: () => import("@/views/pc/navigation/medicine/detail.vue"),
    },
    {
      path: "pc/instruction",
      meta: { title: "说明书" },
      component: () => import("@/views/pc/navigation/product/ProductIndex.vue"),
    },
    {
      path: "pc",
      component: () => import("@/layout/PcAuthorize.vue"),
      children: [
        {
          path: "device",
          meta: { title: "设备中心" },
          component: () =>
            import("@/views/pc/navigation/device/DeviceIndex.vue"),
        },
        {
          path: "device",
          meta: { title: "设备中心" },
          component: () =>
            import("@/views/pc/navigation/device/DeviceIndex.vue"),
        },
        {
          path: "device",
          component: () => import("@/layout/PcDevice.vue"),
          children: [
            {
              path: "detail/:id",
              meta: { title: "设备详情" },
              component: () => import("@/views/pc/monitor/real/RealIndex.vue"),
            },
            {
              path: "pool/:id",
              meta: { title: "泳池信息设置" },
              component: () => import("@/views/pc/pool/detail/DetailIndex.vue"),
            },
            {
              path: "unusual/:id",
              meta: { title: "异常记录查询" },
              component: () => import("@/views/pc/pool/fault/FaultIndex.vue"),
            },
            {
              path: "history/:id",
              meta: { title: "历史数据查询" },
              component: () =>
                import("@/views/pc/pool/history/HistoryIndex.vue"),
            },
            {
              path: "material/:id",
              meta: { title: "设备耗材维护" },
              component: () =>
                import("@/views/pc/pool/material/MaterialIndex.vue"),
            },
            {
              path: "correct/:id",
              meta: { title: "参数配置及校准" },
              component: () =>
                import("@/views/pc/monitor/paramset/ParamsetIndex.vue"),
            },
            {
              path: "switch/:id",
              meta: { title: "报警开关配置" },
              component: () =>
                import(
                  "@/views/pc/monitor/equipliquidset/EquipliquidsetIndex.vue"
                ),
            },
            {
              path: "channel/:id",
              meta: { title: "参数开关配置" },
              component: () =>
                import("@/views/pc/monitor/paramopen/ParamopenIndex.vue"),
            },
            {
              path: "config/:id",
              meta: { title: "设备参数配置" },
              component: () =>
                import("@/views/pc/monitor/equipset/EquipsetIndex.vue"),
            },
            {
              path: "drugconf/:id",
              meta: { title: "加药信息配置" },
              component: () =>
                import("@/views/pc/monitor/pumpset/PumpsetIndex.vue"),
            },
            {
              path: "drug/:id",
              meta: { title: "药泵开关控制" },
              component: () =>
                import("@/views/pc/monitor/pumpopen/PumpopenIndex.vue"),
            },
            {
              path: "shutdown/:id",
              meta: { title: "设备启停控制" },
              component: () =>
                import("@/views/pc/monitor/reset/ResetIndex.vue"),
            },
            {
              path: "httpconf/:id",
              meta: { title: "第三方协议配置" },
              component: () =>
                import("@/views/pc/monitor/protocol/ProtocolIndex.vue"),
            },
            {
              path: "sysinfo/:id",
              meta: { title: "系统信息配置" },
              component: () => import("@/views/pc/new/sysinfo/Sysinfo.vue"),
            },
            {
              path: "factor/:id",
              meta: { title: "监测因子配置" },
              component: () => import("@/views/pc/new/factor/Factor.vue"),
            },
            {
              path: "sensor/:id",
              meta: { title: "设备传感器配置" },
              component: () => import("@/views/pc/new/sensor/Sensor.vue"),
            },
            {
              path: "pumpFactor/:id",
              meta: { title: "药泵因子联动配置" },
              component: () =>
                import("@/views/pc/new/pumpFactor/PumpFactor.vue"),
            },
            {
              path: "pump/:id",
              meta: { title: "加药控制配置" },
              component: () => import("@/views/pc/new/pump/Pump.vue"),
            },
          ],
        },
        // 用户
        {
          path: "profile/mobile",
          meta: { title: "手机绑定" },
          component: () =>
            import("@/views/pc/authorize/mobile/MobileIndex.vue"),
        },
      ],
    },
  ],
};
