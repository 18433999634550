const { reqGet, reqPost, reqUpload } = require("@/utils/request");

export const apiWebEnterpriseBasic = (param) => reqGet("/api/web/enterprise/basic", param);
export const apiWebAuthLogin = (param) => reqPost("/api/web/auth/login", param);
export const apiAuthAccountDetail = (param) => reqGet("/api/auth/account/detail", param);
export const apiAuthAccountLogout = (param) => reqPost("/api/auth/account/logout", param);

export const uploadImg = (param) => reqUpload("/api/com/upload/image", param);
export const uploadVideo = (param) => reqUpload("/api/com/upload/video", param);
export const uploadFile = (param) => reqUpload("/api/com/upload/file", param);

export const comParam = (param) => reqGet("/api/com/baseInfo/base", param);