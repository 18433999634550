import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import VueLocalStorage from "vue-localstorage";
import md5 from "js-md5";
import App from "@/layout/App.vue";
import router from "@/router/index";
import store from "@/store/index";
import config from "@/config";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from "vee-validate";
import {
  alpha,
  alpha_dash,
  alpha_num,
  alpha_spaces,
  between,
  confirmed,
  digits,
  dimensions,
  email,
  excluded,
  ext,
  image,
  oneOf,
  integer,
  length,
  max,
  max_value,
  mimes,
  min,
  min_value,
  numeric,
  regex,
  required,
  required_if,
  size,
} from "vee-validate/dist/rules";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
import PageContent from "@/components/web/PageContent";

extend("alpha", alpha);
extend("alpha_dash", alpha_dash);
extend("alpha_num", alpha_num);
extend("alpha_spaces", alpha_spaces);
extend("between", between);
extend("confirmed", confirmed);
extend("digits", digits);
extend("dimensions", dimensions);
extend("email", email);
extend("excluded", excluded);
extend("ext", ext);
extend("image", image);
extend("oneOf", oneOf);
extend("integer", integer);
extend("length", length);
extend("max", max);
extend("max_value", max_value);
extend("mimes", mimes);
extend("min", min);
extend("min_value", min_value);
extend("numeric", numeric);
extend("regex", regex);
extend("required", required);
extend("required_if", required_if);
extend("size", size);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("PageContent", PageContent);

localize({ zh_CN });
localize("zh_CN");

Vue.config.productionTip = false;
Vue.use(VueLocalStorage);
Vue.use(ElementUI);

const vueapp = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

var timestamp = Date.parse(new Date());
const ms = new Date().getMilliseconds();
const options = {
  clientId: "web" + timestamp + "" + ms,
  clean: true,
  password: "DCB544E2087CEE28",
  username: "device",
  reconnectPeriod: 1000,
  connectTimeout: 30 * 1000,
  resubscribe: true,
  keepalive: 10,
};
const client = mqtt.connect(config.wsHost, options);
client.on("connect", function () {
  console.log("connect success");
  client.subscribe("pool/app/response");
});

client.on("message", (topic, message) => {
  const content = JSON.parse(message.toString());
  if (vueapp.$currentComponent) {
    if (typeof vueapp.$currentComponent.socketResponse === "function") {
      if (!content.route.includes("online")) {
        console.log(content);
      }
      vueapp.$currentComponent.socketResponse(content);
    }
  }
});
// 超过五秒钟提示超时
Vue.prototype.$timeout = () => {
  if (Vue.prototype.$mqtimer) {
    clearTimeout(Vue.prototype.$mqtimer);
    Vue.prototype.$mqtimer = 0;
  } else {
    Vue.prototype.$mqtimer = setTimeout(() => {
      Vue.prototype.$message.error("设备无响应");
      Vue.prototype.$mqtimer = 0;
    }, 5000);
  }
};
