/**
 * web菜单
 */
import config from "@/config";
export default {
  path: config.webPrefix,
  component: () => import("@/layout/Web.vue"),
  children: [
    {
      path: "auth/login",
      meta: { title: "后台登陆" },
      component: () => import("@/views/web/auth/login/LoginIndex.vue"),
    },
    {
      path: "auth/forget",
      meta: { title: "密码找回" },
      component: () => import("@/views/web/auth/forget/ForgetIndex.vue"),
    },
    {
      path: "home",
      component: () => import("@/layout/WebAuthorize.vue"),
      children: [
        {
          path: "dashboard",
          meta: { title: "统计信息" },
          component: () =>
            import("@/views/web/home/dashboard/DashboardIndex.vue"),
        },
      ],
    },
    {
      path: "brand",
      component: () => import("@/layout/WebAuthorize.vue"),
      children: [
        {
          path: "resource",
          meta: { title: "品牌管理" },
          component: () => import("@/views/web/brand/brand/BrandIndex.vue"),
        },
        {
          path: "resource/detail",
          meta: { title: "品牌详情" },
          component: () => import("@/views/web/brand/brand/detail.vue"),
        },
        {
          path: "resource/update",
          meta: { title: "品牌编辑" },
          component: () => import("@/views/web/brand/brand/update.vue"),
        },
        {
          path: "resource/upload",
          meta: { title: "图文上传" },
          component: () => import("@/components/web/UploadMedia.vue"),
        },
      ],
    },
    {
      path: "medicine",
      component: () => import("@/layout/WebAuthorize.vue"),
      children: [
        {
          path: "category",
          meta: { title: "产品分类" },
          component: () =>
            import("@/views/web/medicine/category/CategoryIndex.vue"),
        },
        {
          path: "category/update",
          meta: { title: "产品分类" },
          component: () => import("@/views/web/medicine/category/update.vue"),
        },
        {
          path: "resource",
          meta: { title: "产品管理" },
          component: () =>
            import("@/views/web/medicine/medicine/MedicineIndex.vue"),
        },
        {
          path: "resource/detail",
          meta: { title: "产品详情" },
          component: () => import("@/views/web/medicine/medicine/detail.vue"),
        },
        {
          path: "resource/update",
          meta: { title: "产品编辑" },
          component: () => import("@/views/web/medicine/medicine/update.vue"),
        },
        {
          path: "resource/upload",
          meta: { title: "图文上传" },
          component: () => import("@/components/web/UploadMedia.vue"),
        },
      ],
    },
    {
      path: "product",
      component: () => import("@/layout/WebAuthorize.vue"),
      children: [
        {
          path: "instruction",
          meta: { title: "设备说明书" },
          component: () =>
            import("@/views/web/product/instruction/InstructionIndex.vue"),
        },
        {
          path: "instruction/detail",
          meta: { title: "设备说明书详情" },
          component: () => import("@/views/web/product/instruction/detail.vue"),
        },
        {
          path: "instruction/update",
          meta: { title: "设备说明书编辑" },
          component: () => import("@/views/web/product/instruction/update.vue"),
        },
        {
          path: "instruction/upload",
          meta: { title: "图文上传" },
          component: () => import("@/components/web/UploadMedia.vue"),
        },
      ],
    },
    {
      path: "equipment",
      component: () => import("@/layout/WebAuthorize.vue"),
      children: [
        {
          path: "resource",
          meta: { title: "设备管理" },
          component: () =>
            import("@/views/web/equipment/equipment/EquipmentIndex.vue"),
        },
        {
          path: "detail/:id",
          meta: { title: "设备信息" },
          component: () =>
            import("@/views/web/equipment/equipmentDetail/EquipmentDetail.vue"),
        },
        {
          path: "data",
          meta: { title: "设备数据" },
          component: () => import("@/views/web/equipment/data/Data.vue"),
        },
        {
          path: "dataDetail/:id",
          meta: { title: "数据记录" },
          component: () => import("@/views/web/equipment/detail/Detail.vue"),
        },
        {
          path: "inventory",
          meta: { title: "设备库存" },
          component: () =>
            import("@/views/web/equipment/inventory/InventoryIndex.vue"),
        },

        {
          path: "create",
          meta: { title: "新增设备" },
          component: () =>
            import("@/views/web/equipment/createInventory/CreateInventory.vue"),
        },
        {
          path: "update/:id",
          meta: { title: "设备出库" },
          component: () =>
            import("@/views/web/equipment/updateInventory/UpdateInventory.vue"),
        },
      ],
    },
    {
      path: "enterprise",
      component: () => import("@/layout/WebAuthorize.vue"),
      children: [
        {
          path: "org",
          meta: { title: "用户管理" },
          component: () => import("@/views/web/enterprise/org/OrgIndex.vue"),
        },
        {
          path: "create",
          meta: { title: "新增用户" },
          component: () => import("@/views/web/enterprise/create/Create.vue"),
        },
        {
          path: "update/:id",
          meta: { title: "用户信息" },
          component: () => import("@/views/web/enterprise/update/Update.vue"),
        },
        {
          path: "account",
          meta: { title: "人员管理" },
          component: () =>
            import("@/views/web/enterprise/account/AccountIndex.vue"),
        },
      ],
    },
    {
      path: "advert",
      component: () => import("@/layout/WebAuthorize.vue"),
      children: [
        {
          path: "banner",
          meta: { title: "广告管理" },
          component: () => import("@/views/web/advert/banner/BannerIndex.vue"),
        },
        {
          path: "banner/edit",
          meta: { title: "广告管理" },
          component: () => import("@/views/web/advert/banner/edit.vue"),
        },
      ],
    },
    {
      path: "setting",
      component: () => import("@/layout/WebAuthorize.vue"),
      children: [
        {
          path: "enterprise",
          meta: { title: "组织管理" },
          component: () =>
            import("@/views/web/setting/enterprise/EnterpriseIndex.vue"),
        },
        {
          path: "enterprise/detail",
          meta: { title: "组织详情" },
          component: () => import("@/views/web/setting/enterprise/detail.vue"),
        },
        {
          path: "enterprise/update",
          meta: { title: "修改组织" },
          component: () => import("@/views/web/setting/enterprise/update.vue"),
        },
      ],
    },
    {
      path: "report",
      component: () => import("@/layout/WebAuthorize.vue"),
      children: [
        {
          path: "material",
          meta: { title: "耗材维护" },
          component: () =>
            import("@/views/web/report/material/MaterialIndex.vue"),
        },
        {
          path: "early",
          meta: { title: "设备报警" },
          component: () => import("@/views/web/report/early/EarlyIndex.vue"),
        },
        {
          path: "early/detail",
          meta: { title: "设备报警" },
          component: () => import("@/views/web/report/early/detail.vue"),
        },
        {
          path: "drug",
          meta: { title: "设备投药" },
          component: () => import("@/views/web/report/drug/DrugIndex.vue"),
        },
        {
          path: "drug/detail",
          meta: { title: "设备投药详情" },
          component: () => import("@/views/web/report/drug/detail.vue"),
        },
        {
          path: "fault",
          meta: { title: "异常记录" },
          component: () => import("@/views/web/report/fault/FaultIndex.vue"),
        },
        {
          path: "fault/detail",
          meta: { title: "异常记录" },
          component: () => import("@/views/web/report/fault/detail.vue"),
        },
        {
          path: "pool",
          meta: { title: "休闲水体" },
          component: () => import("@/views/web/report/pool/PoolIndex.vue"),
        },
        {
          path: "pool/detail/:id",
          meta: { title: "休闲水体" },
          component: () => import("@/views/web/report/pool/PoolDetail.vue"),
        },
        {
          path: "login",
          meta: { title: "登录记录" },
          component: () => import("@/views/web/report/login/LoginIndex.vue"),
        },
        {
          path: "control",
          meta: { title: "操作记录" },
          component: () => import("@/views/web/report/operate/index.vue"),
        },
        {
          path: "control/detail",
          meta: { title: "操作记录详情" },
          component: () => import("@/views/web/report/operate/detail.vue"),
        },
      ],
    },
  ],
};
