import Vue from "vue";
import axios from "axios";
import Qs from "qs";
import md5 from "js-md5";
import config from "@/config";

axios.defaults.baseURL = config.host;
axios.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 网络请求异常处理
 * @param {*} error
 */
const responseError = (error) => {
  if (error.response) {
    const response = error.response;
    console.log(response.data);
  } else {
    console.log(error);
  }
};

/**
 * 请求成功处理
 * @param {*} res
 * @param {*} resolve
 * @param {*} reject
 */
const responseSuccess = (response, resolve, reject) => {
  const res = response.data;
  if (res.code === 100) {
    resolve(res.data);
  } else {
    reject(res);
  }
};

/**
 * @desc 获取get请求
 * @param {*} url
 * @param {*} query
 */
export const reqGet = (url, query = {}) => {
  const session = Vue.localStorage.get(md5("session"));
  if (session) {
    query.token = session;
  }
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      params: query,
      method: "get",
    })
      .then((res) => responseSuccess(res, resolve, reject))
      .catch((error) => responseError(error));
  });
};

/**
 * @desc 获取get请求
 * @param {*} url
 * @param {*} query
 */
export const reqPost = (url, params = {}) => {
  const session = Vue.localStorage.get(md5("session"));
  if (session) {
    params.token = session;
  }
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      data: Qs.stringify(params),
      method: "post",
    })
      .then((res) => responseSuccess(res, resolve, reject))
      .catch((error) => responseError(error));
  });
};

/**
 * @desc 文件上传
 * @param {*} url
 * @param {*} query
 */
export const reqUpload = (url, formData) => {
  const session = Vue.localStorage.get(md5("session"));
  if (session) formData.append("token", session);
  let headers = {
    "Content-Type": "multipart/form-data",
  };
  let params = {
    url: url,
    method: "POST",
    headers: headers,
    data: formData,
  };
  return new Promise((resolve, reject) => {
    axios(params)
      .then((res) => responseSuccess(res, resolve, reject))
      .catch((error) => responseError(error));
  });
};
