import Vue from "vue";
import Vuex from "vuex";
import md5 from "js-md5";
import config from "@/config";
import { apiAuthAccountDetail, apiWebEnterpriseBasic } from "@/apis/common";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    config: config,
    authroize: {
      user: {},
    },
    uri: {
      prefix: "",
      page: "",
      url: "",
    },
    company: {},
    title: "",
    equip: {
      id: "",
      unique_code: "",
      data_time: "",
    },
    collapse: false,
  },
  mutations: {
    updateUserInfo(state, authroize) {
      state.authroize = authroize;
    },
    updateUri(state, uri) {
      state.uri = uri;
    },
    updateCompany(state, company) {
      state.company = company;
    },
    updateEquip(state, equip) {
      state.equip = equip;
    },
    updateTitle(state, title) {
      state.title = title;
    },
    updateCollapse(state, collapse) {
      state.collapse = collapse;
    },
  },
  actions: {
    // 获取客户信息
    pageInit({ commit, state }, _this) {
      Vue.prototype.$currentComponent = _this;
      const userInfo = _this.$localStorage.get(md5("user"));
      if (userInfo) {
        commit("updateUserInfo", JSON.parse(userInfo));
      }
      return new Promise((resolve) => {
        apiAuthAccountDetail()
          .then((res) => {
            _this.$localStorage.set(md5("user"), JSON.stringify(res));
            commit("updateUserInfo", res);
            resolve(res);
          })
          .catch((err) => {
            _this.$localStorage.remove(md5("session"));
            _this.$localStorage.remove(md5("user"));
            if (state.uri.prefix === config.pcPrefix) {
              _this.$router.replace({ path: config.pcPrefix + "/pc/login" });
            } else {
              _this.$router.replace({ path: config.webPrefix + "/auth/login" });
            }
          });
      });
    },
    companyInit({ commit }, _this) {
      const company = _this.$localStorage.get(md5("company"));
      if (company) {
        commit("updateCompany", JSON.parse(company));
      }
      return new Promise((resolve, reject) => {
        apiWebEnterpriseBasic({
          code: config.enterCode,
        })
          .then((res) => {
            _this.$localStorage.set(md5("company"), JSON.stringify(res.detail));
            commit("updateCompany", res.detail);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadEquip({ commit }, equip) {
      commit("updateEquip", equip);
    },
  },
  modules: {},
});
